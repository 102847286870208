import React from "react";
import Display from "./components/Display";
import Mynav from "./components/Mynav";
import Calc from "./components/Calc";
import { Grid } from "@material-ui/core";
import Funds from "./components/Funds";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import MyChart from "./components/MyChart";
import MyCalc from "./components/MyCalc";
import Myfunds from "./components/Myfunds";

import "./App.css";

function App() {
  return (
    <div className='App'>
      <Grid container direction='column'>
        {/* GRID START */}
        <Mynav></Mynav>
        <Myfunds></Myfunds>

        <MyCalc></MyCalc>
        {/* <Typography id='discrete-slider-always' gutterBottom>
          ביחרו או הקלידו קופות להשוואה
        </Typography>
        <Typography id='discrete-slider-always' gutterBottom>
          ניתן להקליד מספרי אוצר או שם חלקי
        </Typography> */}

      {/* <Funds></Funds> */}
        {/* GRID END */}
      </Grid>
    </div>
  );
}

export default App;
