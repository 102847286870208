import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FundMe from "./Fundme.js";
import Fundme from "./Fundme.js";

const Myfunds = (props) => {
  return (
    <Paper elevation={3}>
      <Grid container direction='row' justify='center'>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='h11'></Fundme>
          </Paper>
        </Grid>

        {/* <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='h12'></Fundme>{" "}
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='h13'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='h14'></Fundme>{" "}
          </Paper>
        </Grid>
      </Grid>

      <Grid container direction='row' justify='center'>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='h21'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='h22'></Fundme>{" "}
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='h23'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='h24'></Fundme>{" "}
          </Paper>
        </Grid>
      </Grid>

      <Grid container direction='row' justify='center'>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g11'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g12'></Fundme>{" "}
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g13'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g14'></Fundme>{" "}
          </Paper>
        </Grid>
      </Grid>
      <Grid container direction='row' justify='center'>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g21'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g22'></Fundme>{" "}
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g23'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g24'></Fundme>{" "}
          </Paper>
        </Grid>
      </Grid>

      <Grid container direction='row' justify='center'>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g31'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g32'></Fundme>{" "}
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g33'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g34'></Fundme>{" "}
          </Paper>
        </Grid>
      </Grid>
      <Grid container direction='row' justify='center'>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g41'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g42'></Fundme>{" "}
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g43'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='g44'></Fundme>{" "}
          </Paper>
        </Grid>
      </Grid>
      <Grid container direction='row' justify='center'>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='gh11'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='gh12'></Fundme>{" "}
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='gh13'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='gh14'></Fundme>{" "}
          </Paper>
        </Grid>
      </Grid>
      <Grid container direction='row' justify='center'>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='gh21'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='gh22'></Fundme>{" "}
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='gh23'></Fundme>{" "}
          </Paper>
        </Grid>

        <Grid item>
          <Paper elevation={3}>
            <Fundme toShow='gh24'></Fundme>{" "}
          </Paper>
        </Grid> */}
      </Grid>
    </Paper>
    /* 
      <Fundme toShow='h11'></Fundme>
      <Fundme toShow='h12'></Fundme>
      <Fundme toShow='h13'></Fundme>

      <Fundme toShow='h21'></Fundme>
      <Fundme toShow='h22'></Fundme>
      <Fundme toShow='h23'></Fundme>

      <Fundme toShow='g11'></Fundme>
      <Fundme toShow='g12'></Fundme>
      <Fundme toShow='g13'></Fundme>

      <Fundme toShow='g21'></Fundme>
      <Fundme toShow='g22'></Fundme>
      <Fundme toShow='g23'></Fundme>
      <Fundme toShow='g24'></Fundme>
      <Fundme toShow='g31'></Fundme>
      <Fundme toShow='g32'></Fundme>
      <Fundme toShow='g33'></Fundme>
      <Fundme toShow='g34'></Fundme>
      <Fundme toShow='g41'></Fundme>
      <Fundme toShow='g42'></Fundme>
      <Fundme toShow='g43'></Fundme>

      <Fundme toShow='gh11'></Fundme>
      <Fundme toShow='gh12'></Fundme>
      <Fundme toShow='gh13'></Fundme>

      <Fundme toShow='gh21'></Fundme>
      <Fundme toShow='gh22'></Fundme>
      <Fundme toShow='gh23'></Fundme> */
  );
};

export default Myfunds;
