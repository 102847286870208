import React, { useState, useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";

const FormatTooltip = (props) => {
  const { children, value, open } = props;

  const popperRef = useRef(null);
  useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      title={value.toLocaleString()}
      open={open}
      placement='top'
      PopperProps={{ popperRef }}
    >
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //  width: 300,
    padding: 40,
  },
  margin: {
    height: theme.spacing(0),
  },
}));

const marks = [
  {
    value: 0,
    label: " 0 ",
  },
  {
    value: 5000,
    label: "5,000 ",
  },
];

const marks2 = [
  {
    value: 0,
    label: " 0 ",
  },
  {
    value: 1000000,
    label: " 1,000,000   ",
  },
];

const marks3 = [
  {
    value: 1,
    label: " 1 ",
  },
  {
    value: 60,
    label: " 60  ",
  },
];
const marks4 = [
  {
    value: 0,
    label: " 0% ",
  },
  {
    value: 30,
    label: " 30%  ",
  },
];

function valuetext(value) {
  return `ש"ח ${value}`;
}

const Calc = (props) => {
  const [selectedValue, setSelectedValue] = useState({
    month: 500,
    single: 0,
    term: 5,
    precent: 4,
  });

  const [update, setUpdate] = useState(0);

  const [myResult, setResult] = useState("");

  const myresult = () => {
    // console.log(myResult);
    const arr = [0, 0];
    const intrest =
      Math.pow(1 + parseFloat(selectedValue.precent) / 100, 1 / 12) - 1;
    for (let i = 0; i < parseInt(selectedValue.term) * 12; i++) {
      // console.log(i + 1);
      arr[0] += Math.pow(1 + intrest, i + 1);
    }
    arr[0] *= selectedValue.month;
    arr[1] =
      selectedValue.single * Math.pow(1 + intrest, selectedValue.term * 12);
    // console.log(arr[0]);
    // console.log(arr[1]);
    setResult(Math.round(arr[0] + arr[1]));
    const result = myResult;
    props.parentCallback([
      //  selectedValue.precent,
      // selectedValue.term,
      // selectedValue.single,
      // selectedValue.month,
      myResult,
      selectedValue.month,
      selectedValue.single,
      selectedValue.term,
    ]);
    return (
      // parseInt(selectedValue.month) +
      // parseInt(selectedValue.single) +
      // parseInt(selectedValue.term) +
      // parseFloat(selectedValue.precent)

      1
    );
  };

  useEffect(() => {
    myresult();
  }, [selectedValue, myResult]);

  const monthDepositHandler = (event, newValue) => {
    setSelectedValue({
      ...selectedValue,
      month: newValue,
    });
    // console.log(selectedValue);
    // }
    //  myresult();
  };

  const singleDepositHandler = (event, newValue) => {
    // if (newValue) {
    setSelectedValue({
      ...selectedValue,
      single: newValue,
    });
    //  console.log("value: " + selectedValue.single + "new value: " + newValue);
    //  console.log("value: " + selectedValue.single + "new value: " + newValue);
    // }

    //  myresult();
  };

  const termHandler = (event, newValue) => {
    // if (newValue) {
    setSelectedValue({
      ...selectedValue,
      term: newValue,
    });
    //   console.log(selectedValue);
    // }
    //   myresult();
  };

  const precentHandler = (event, newValue) => {
    //  if (newValue) {
    setSelectedValue({
      ...selectedValue,
      precent: newValue,
    });
    //  console.log(selectedValue);
    //  }
    //   myresult();
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography id='discrete-slider-always' gutterBottom>
        :מחשבון חיסכון אינטראקטיבי
      </Typography>

      <Typography id='discrete-slider-always' gutterBottom>
        יש לבחור את תקופת החיסכון ,התשואה השנתית,סכום ההפקדה החד פעמי וסכום
        ההפקדה החודשית
      </Typography>
      <br></br>
      <Typography>סכום הפקדה חודשי</Typography>

      <Slider
        ValueLabelComponent={FormatTooltip}
        onChange={monthDepositHandler}
        defaultValue={500}
        getAriaValueText={valuetext}
        step={100}
        marks={marks}
        valueLabelDisplay='on'
        min={0}
        max={5000}
      />

      <br></br>
      <br></br>
      <Typography>סכום הפקדה חד פעמי</Typography>
      <Slider
        ValueLabelComponent={FormatTooltip}
        onChange={singleDepositHandler}
        defaultValue={0}
        getAriaValueText={valuetext}
        step={10000}
        marks={marks2}
        valueLabelDisplay='on'
        min={0}
        max={1000000}
      />

      <br></br>
      <br></br>
      <Typography>תקופת חיסכון בשנים</Typography>
      <Slider
        ValueLabelComponent={FormatTooltip}
        onChange={termHandler}
        defaultValue={5}
        getAriaValueText={valuetext}
        step={1}
        marks={marks3}
        valueLabelDisplay='on'
        min={1}
        max={60}
      />

      <br></br>
      <br></br>
      <Typography>תשואה שנתית</Typography>
      <Slider
        ValueLabelComponent={FormatTooltip}
        onChange={precentHandler}
        defaultValue={4}
        getAriaValueText={valuetext}
        step={0.5}
        marks={marks4}
        valueLabelDisplay='on'
        min={0}
        max={30}
      />
      <Button onClick={myresult} variant='contained' color='primary'>
        חשב
      </Button>
      <input
        type='text'
        placeholder='תוצאה'
        defaultValue={myResult.toLocaleString()}
      ></input>
      <Typography id='discrete-slider-always' gutterBottom>
        המחשבון אינו לוקח בחשבון מיסוי ,דמי ניהול ואת מדד המחירים לצרכן
      </Typography>
      <Typography id='discrete-slider-always' gutterBottom>
        המחשבון מחשב מתמטית
      </Typography>
    </div>
  );
};

export default Calc;
