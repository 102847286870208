import React, { useState, useEffect, useRef } from "react";
import Calc from "./Calc";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MyChart from "./MyChart";

const MyCalc = (props) => {
  const [myData, setMyData] = useState("");
  const callbackFunction = (childData) => {
    setMyData(childData);
  };

  return (
    <Paper elevation={3}>
      {
        //start
      }
      <Grid container direction='row' justify='center'>
        <Grid item>
          <Paper elevation={3}>
            <Calc parentCallback={callbackFunction}></Calc>
          </Paper>
        </Grid>

        <Paper elevation={3}>
          <Grid item>
            <MyChart data={myData}></MyChart>
          </Grid>
        </Paper>
      </Grid>
      {
        //end
      }
    </Paper>
  );
};

export default MyCalc;
