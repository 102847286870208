import React, { useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const MyChart = (props) => {
  const data = [
    {
      name: "",
      קרן: props.data[1] * props.data[3] * 12 + props.data[2],
      רווחים:
        props.data[0] - props.data[1] * props.data[3] * 12 - props.data[2],
      סהכ: props.data[0],
    },
  ];
  // console.log(props.data[0]);
  // console.log(data);
  return (
    <BarChart
      width={400}
      height={500}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='name' />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey='קרן' fill='#0000FF' />
      <Bar dataKey='רווחים' fill='#9ACD32' />
      <Bar dataKey='סהכ' fill='#FF8C00' />
    </BarChart>
  );
};
export default MyChart;
