import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const Fundme = (props) => {
  // var data = "";
  var myarray = [];
  const [myData, setmyData] = useState("test");
  // console.log(props.toShow);

  //  console.log(props.toShow);

  useEffect(() => {
    fetch(
      // `https://data.gov.il/api/action/datastore_search?q=1093&resource_id=a30dcbea-a1d2-482c-ae29-8f781f5025fb`
      `https://isavemoney.info/index3.php?t=${props.toShow}`
      // { mode: "no-cors" }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //console.log(myData);
      });
  }, []);

  return <div></div>;
};

export default Fundme;
