//import React, { useState, useEffect, useRef } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//import Card from "@material-ui/core/Card";
////import CardActions from "@material-ui/core/CardActions";
//import CardContent from "@material-ui/core/CardContent";
//import Button from "@material-ui/core/Button";
//import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Fund = (props) => {
  const classes = useStyles();

  if (props.data) {
    var sum = 0;

    var Beforemonths =
      12 -
      (props.data["REPORT_PERIOD"] * 1).toString()[5] -
      10 * (props.data["REPORT_PERIOD"] * 1).toString()[4];

    if (props.lastyear) {
      sum = props.data["YEAR_TO_DATE_YIELD"] / 100 + 1;

      if (props.lastyear.length != 0)
        for (var x = 12; x > 12 - Beforemonths; x--) {
          sum *= props.lastyear[x - 1]["MONTHLY_YIELD"] / 100 + 1;
        }
      sum = ((sum - 1.0) * 100).toFixed(2);
    }

    return (
      <div>
        <h1>{props.data["FUND_NAME"]}</h1>
        <h4>
          סוג מוצר:{props.data["FUND_CLASSIFICATION"]}
          <br></br>
          {props.data["YEAR_TO_DATE_YIELD"]} :תשואה מתחילת השנה <br></br>
          <br></br>
          {props.data["MONTHLY_YIELD"]} :תשואת חודש אחרון <br></br>
          <br></br>
          {sum} :תשואה 12 חודשים אחרונים <br></br>
          <br></br>
          {props.data["YIELD_TRAILING_3_YRS"]} :תשואה מצטברת 3 שנים <br></br>
          <br></br>
          {props.data["YIELD_TRAILING_5_YRS"]} :תשואה מצטברת 5 שנים <br></br>
          <br></br>
          {props.data["AVG_ANNUAL_YIELD_TRAILING_3YRS"]} :תשואה שנתית ממוצעת 3
          שנים <br></br>
          {props.data["AVG_ANNUAL_YIELD_TRAILING_5YRS"]} :תשואה שנתית ממוצעת 5
          שנים <br></br>
          התמחות:{props.data["SPECIALIZATION"]}
          <br></br>
          {props.data["REPORT_PERIOD"][4]}
          {props.data["REPORT_PERIOD"][5]}/{props.data["REPORT_PERIOD"][2]}
          {props.data["REPORT_PERIOD"][3]} :מעודכן לסוף חודש <br></br>
        </h4>
        {(
          (props.data["STOCK_MARKET_EXPOSURE"] / props.data["TOTAL_ASSETS"]) *
          100
        ).toFixed(2)}{" "}
        אחוז חשיפה למניות <br></br>
        <br></br>
      </div>
    );
  }
  return "";
};

export default Fund;
